
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "search-supplier-intra-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let jobs = ref([]);
        let nationalities = ref([]);
        let suppliersIntra = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchEmployeeIntraModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            supplier_intra_id: 0,
            code             : "",
            last_name        : "",
            first_name       : "",
            gender           : "",
            id_number        : "",
            nationality      : "",
            job              : "",
        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchEmployeeIntraModalRef.value);
        };

        const getJobs = async () => {
            const response = await axios.get(ApiRoutes.employeesIntra.jobs);

            jobs.value = response.data;
        };

        const getSuppliersIntra = async () => {
            const response = await axios.get(ApiRoutes.suppliersIntra.list);

            suppliersIntra.value = response.data;
        };

        const getNationalities = async () => {
            const response = await axios.get(ApiRoutes.countries.nationalities);

            nationalities.value = response.data;
        };

        onMounted(() => {
            getJobs();
            getNationalities();
            getSuppliersIntra();

            const modal = document.getElementById('kt_modal_search_employee_intra');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });
        });

        return {
            translate,
            jobs,
            nationalities,
            suppliersIntra,
            queryParams,
            submit,
            submitButtonRef,
            searchEmployeeIntraModalRef,
        };
    },
});
