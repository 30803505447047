
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditEmployeeIntraModal from "@/components/modals/referencials/employees-intra/EditEmployeeIntraModal.vue";
import SearchEmployeeIntraModal from "@/components/modals/referencials/employees-intra/SearchEmployeeIntraModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-employeesIntra",
    props: {
        widgetClasses: String,
    },
    components: {
        EditEmployeeIntraModal,
        SearchEmployeeIntraModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeEmployeeIntra = ref(null);
        const employeesIntra = ref([]);
        const queryParamsDefault = {
            supplier_intra_id: 0,
            code             : "",
            last_name        : "",
            first_name       : "",
            gender           : "",
            id_number        : "",
            nationality      : "",
            job              : "",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            supplier_intra_id: translate("employeeIntraSupplierIntraIdLabel"),
            code             : translate("employeeIntraCodeLabel"),
            last_name        : translate("employeeIntraLastNameLabel"),
            first_name       : translate("employeeIntraFirstNameLabel"),
            gender           : translate("employeeIntraGenderLabel"),
            id_number        : translate("employeeIntraIdNumberLabel"),
            nationality      : translate("employeeIntraNationalityLabel"),
            job              : translate("employeeIntraJobLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getEmployeesIntra();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getEmployeesIntra();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getEmployeesIntra();
        };

        const create = () => {
            activeEmployeeIntra.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_employee_intra'));
            modal.show();
        };

        const edit = (supplier) => {
            activeEmployeeIntra.value = supplier;
            const modal = new Modal(document.getElementById('kt_modal_edit_employee_intra'));
            modal.show();
        };

        const confirmRemove = (supplier_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("employeeIntraConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(supplier_id);
                }
            })
        }

        const remove = (employee_id: number) => {

            axios.delete(`${ApiRoutes.employeesIntra.delete}/${employee_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getEmployeesIntra = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.employeesIntra.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    employeesIntra.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchEmployeesIntra = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getEmployeesIntra();

            setCurrentPageBreadcrumbs(translate("employeeIntraManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("employeesManagementHeading")
            ]);
        });

        return {
            translate,
            employeesIntra,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            removeQueryParam,
            searchEmployeesIntra,
            pagination,
            activeEmployeeIntra,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
